<template>
  <v-card
    eagle-photo-selector="photo-item"
    :max-width="photoSize"
  >
    <v-img
      class="white--text align-end lighten-2"
      :height="photoSize"
      :width="photoSize"
      :position="position"
      :class="imageClass"
      :src="photoUrl"
      :lazy-src="lazySrc"
    >
      <!--placeholder-->
      <div
        v-if="!photoUrl"
        eagle-photo-selector="placeholder"
      >
        <v-icon
          v-bind="iconProperty"
        >fa fa-image</v-icon>
      </div>

      <!--拖曳-->
      <v-btn
        v-if="draggable"
        eagle-photo-selector="draggable-button"
        class="photo-selector-draggable"
        color="orange"
        icon
      >
        <v-icon>fa fa-bars</v-icon>
      </v-btn>

      <!--移除-->
      <v-btn
        v-if="removable"
        @click="remove"
        eagle-photo-selector="remove-button"
        color="red"
        icon
      >
        <v-icon>fa fa-trash</v-icon>
      </v-btn>

      <!-- 圖片名稱(alt) -->
      <v-card-title v-if="name" v-show="!previewMode">
        <v-chip
          label
          small
        >
          {{name}}
        </v-chip>
      </v-card-title>
    </v-img>

    <v-card-actions v-if="!previewMode && !disabled">
      <v-spacer></v-spacer>

      <!-- 檢視 -->
      <viewPhoto
        :photo="photo"
      ></viewPhoto>

      <!-- 複製圖片連結 -->
      <copyLink
        :photo="photo"
      ></copyLink>

      <!-- 編輯圖片名稱 -->
      <editName
        @updatePhotoName="updatePhotoName"
        :photo="photo"
      ></editName>

      <!-- 上傳 -->
      <upload
        @changePhoto="changePhoto"
        :photo="photo"
        :type="type"
      ></upload>

      <!-- 圖片管理-->
      <photoManager
        v-if="photoManager"
        @changePhoto="changePhoto"
        :photo="photo"
        :type="type"
      ></photoManager>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    disabledRemove: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    value: {
      type: Object,
      default: () => null,
    },
    options: {
      type: Object,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    photoManager: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    photo: null
  }),
  created() {
    this.init()
  },
  methods: {
    init() {
      this.photo = window.eagleLodash.cloneDeep(this.value)
    },
    remove() {
      this.$apopup.base({
        title: this.$t(`remove.confirm`),
        applyCallback: () => this.photo = null,
      })
    },
    update(photo) {
      this.photo = photo
    },
    updatePhotoName(name) {
      this.$set(this.photo, 'name', name)
    },
    changePhoto(photo) {
      this.photo = photo
    },
  },
  computed: {
    removable() {
      if(!this.photo) return false
      if(this.disabled) return false
      if(this.disabledRemove) return false
      return true
    },
    name() {
      if(!this.photo) return null
      return this.photo.name
    },
    iconProperty() {
      if(!this.previewMode) {
        return {
          large: true,
        }
      }

      return {
        size: '20'
      }
    },
    photoUrl() {
      const url = this.$helper.getPhotoUrl(this.photo, ['small', 'tiny'])
      if(!url) return ''
      return url
    },
    lazySrc() {
      const url = this.$helper.getPhotoUrl(this.photo, ['tiny', 'small', 'middle', 'large'])
      if(!url) return ''
      return url
    },
    position() {
      if(!this.options) return null
      return this.options.position
    },
    photoSize() {
      if(this.previewMode) return '120px'
      return '200px'
    },
    imageClass() {
      if(!this.photoUrl) return 'grey'
      return null
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.init()
      },
    },
    photo: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.value, this.photo)) return
        this.$emit('input', window.eagleLodash.cloneDeep(this.photo))
      },
    },
  },
  components: {
    viewPhoto: () => import('./action/view.vue'),
    copyLink: () => import('./action/copyLink.vue'),
    upload: () => import('./action/upload.vue'),
    editName: () => import('./action/editName.vue'),
    photoManager: () => import('./action/photoManager.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
*[eagle-photo-selector="photo-item"]
  div[eagle-photo-selector="placeholder"]
    position: absolute
    top: 45%
    left: 40%

  button[eagle-photo-selector="draggable-button"]
    position: absolute
    top: 0
    left: 0
    margin: 5px
  button[eagle-photo-selector="remove-button"]
    position: absolute
    top: 0
    right: 0
    margin: 5px
</style>
